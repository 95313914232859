import React from 'react';

interface Props {
    label: string
}

const Separator = (props: Props) => {
    return (
        <div className='w-100 d-flex align-items-center'>
            <hr className='flex-grow-1' />
            <span className='mx-2 text-condensed'>{props.label}</span>
            <hr className='flex-grow-1' />
        </div>
    );
}

export default Separator;