import React from 'react';
import { Offer } from '../../models/Offer';
import { ListGroup, Row, Col, Button } from 'react-bootstrap';
import { FaExternalLinkAlt, FaExternalLinkSquareAlt } from 'react-icons/fa';

interface Props {
    offers: Offer[];
}

const OfferTable = (props: Props) => {



    return (
        <>
            {props.offers.map((offer, i) => (
                <Row key={`offer-${i}`} className='bg-white rounded shadow my-3 p-2 text-left'>
                    <Col sm xs='6'>{offer.amount}</Col>
                    <Col sm xs='6'>{offer.term}<span className='text-muted ml-1' style={{ fontSize: 10 }}>months</span></Col>
                    <Col sm xs='6'>{offer.paymentAmount}<span className='text-muted ml-1' style={{ fontSize: 10 }}>{offer.paymentFrequency}</span></Col>
                    <Col sm xs='6'>{offer.fee}<span className='text-muted ml-1' style={{ fontSize: 10 }}>fee</span></Col>
                    <Col sm xs='6'>{offer.rate}%</Col>
                    <Col md xs='12' className='text-right mt-2 mt-md-0'>
                        <a href={offer.acceptURL} target='_blank' rel='noopener noreferrer' className='text-decoration-none'>
                            <Button variant='outline-primary' className='w-100' size='sm'>Accept <FaExternalLinkAlt /></Button>
                        </a>
                    </Col>
                </Row>
            ))}
        </>
    );
}

export default OfferTable;