import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../AppContext';

interface Props {

}

const Footer = (props: Props) => {
    const context = useContext(AppContext);

    return (
        <div className='no-print mb-3 d-flex justify-content-center align-items-center flex-column'>
            <p className='mt-3 mb-0 text-light-gray text-upper text-center'>© 2008-{new Date().getFullYear()} Express Tech-Financing, LLC, all rights reserved.</p>
            <div style={{ textDecoration: 'none' }} className='font-weight-bold'>
                <a className='text-dark-gray' href='https://www.gocurrency.com/privacy-policy/' target = '_blank'>Privacy</a>
                <i className='mx-2' />
                <a className='text-dark-gray' href='https://www.gocurrency.com/terms-of-use/' target='_blank'>Terms of Use</a>
                <i className='mx-2' />
                <a className='text-dark-gray' href='https://www.gocurrency.com/legal/' target='_blank'>Legal</a>
                <i className='mx-2' />
                <a className='text-dark-gray' href={`mailto:${context.supportEmail}`}>Customer Care</a>
            </div>
        </div>
    );
}

export default Footer;