import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { AutocompleteInput, AutocompleteInputCallbackType } from 'sandhills-react-bootstrap-ui';
import AsyncCreatableSelect from 'react-select/async-creatable';
import FlowProvider from '../../providers/FlowProvider';
import { FaSearch } from 'react-icons/fa';

interface Props {
    id?: string,
    className?: string,
    disabled?: boolean,
    placeholder?: string,
    value?: { value: any, label: string, isDisabled?: boolean } | null,
    onChange: (option: { value: any, label: string } | null) => void,
}

const ManufacturerAutocomplete = (props: React.PropsWithChildren<Props>) => {

    const context = useContext(AppContext);

    const searchManufacturers: AutocompleteInputCallbackType = (input, callback) => {
        if (!input || input.length < 2) {
            callback([]);
            return;
        }

        FlowProvider.SearchManufacturers(context.vendorID, context.topLevelPartnerVendorID, input).then((result) => {
            if (result) {
                callback(result.options);
            } else {
                callback([]);
            }
        });
    }

    // raw dog some of the props to the autocomplete input
    const rawProps = {
        components: {
            DropdownIndicator: () => <FaSearch style={{ marginRight: 10, marginLeft: 10 }} className='text-light-gray' />, // Override the arrow
            // IndicatorSeparator: () => null, // Without arrow, don't want the separator
        },
        noOptionsMessage: () => null, // No menu until there are options
    };


    return (
        <AutocompleteInput {...props} rawAsyncSelectProps={rawProps} allowTypeIn disabled={props.disabled || props.value?.isDisabled} clearable fetchOptions={searchManufacturers} >
            {props.children}
        </AutocompleteInput>
    )
}

export default ManufacturerAutocomplete;