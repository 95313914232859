import React, { useEffect } from 'react';
import { DropdownInput } from 'sandhills-react-bootstrap-ui';
import { SelectOption } from '../../models/SelectOption';
import FlowProvider from '../../providers/FlowProvider';

interface Props {
    value?: SelectOption,
    className?: string,
    onChange: (value: { label: string, value: any } | null) => void
}

// const options = ['Employed', 'Business Owner / Self Employed', 'Unemployed', 'Retired'].map(month => ({ label: month, value: month }));

const EmploymentStatusDropdown = (props: React.PropsWithChildren<Props>) => {

    const [options, setOptions] = React.useState<SelectOption[]>([]);

    useEffect(() => {
        FlowProvider.GetEmploymentTypes().then((result) => {
            if (result)
                setOptions(result.options);
        });
    }, []);

    return (
        <DropdownInput searchable value={props.value} className={props.className} onChange={props.onChange} options={options}>
            {props.children}
        </DropdownInput>
    )
}

export default EmploymentStatusDropdown;