
import { Base } from '../../models/Base';
import { toast } from 'react-toastify';

export default class FetchProvider {
    // static MAX_FILE_SIZE = 1048576;
    // static MAX_UPLOAD_RETRIES = 5;

    static fetch = <T extends Base>(url: string, input?: any): Promise<T | null | undefined> => {
        if (url) {
            let config: RequestInit = {};
            if (input) {
                let headers = new Headers();
                if (!(input instanceof FormData))
                    headers.append('Content-Type', 'application/json');
                config.headers = headers;
                config.method = 'POST';
                config.body = input instanceof FormData ? input : JSON.stringify(input);
            }

            return window.fetch(url, config).then((response) => {
                if (response.ok && !response.redirected) {
                    const cloned = response.clone();

                    return Promise.resolve().then(() => {
                        if (response.headers.has('Content-Type') && response.headers.get('Content-Type')?.includes('application/json')) {
                            return response.json().then((data: T) => {
                                if (!data || (!data.success && data.displayError)) {
                                    console.log(data.detailedErrorMessage);
                                    toast.error(data.messageToDisplay);
                                    return null;
                                } else if (!data.success && !data.displayError) {
                                    console.log(data.detailedErrorMessage);
                                    return null;
                                } else {
                                    return data;
                                }
                            });
                        } else if (response.headers.has('Content-Disposition') && response.headers.get('Content-Disposition')?.includes('attachment')) {
                            // It's a file
                            return response.blob().then((file: Blob) => {
                                // Try to get the file name from the response
                                let fileName;
                                if (response.headers.get('Content-Disposition')?.includes('filename')) {
                                    fileName = response.headers.get('Content-Disposition')?.split('filename=')[1].split(';')[0];
                                }

                                const fileURL = window.URL.createObjectURL(file);
                                const fileDownloadElement: HTMLAnchorElement = document.createElement('a');
                                fileDownloadElement.hidden = true;
                                fileDownloadElement.href = fileURL;
                                if (fileName)
                                    fileDownloadElement.download = fileName;
                                const body = document.querySelector('body');
                                body?.appendChild(fileDownloadElement);

                                fileDownloadElement.click();

                                return null;
                            });
                        } else {
                            console.error(`Unknown return type from ${url}. For file downloads, make sure to set the FileDownloadName on the FileStreamResult.`);
                            return null;
                        }
                    }).catch((err) => {
                        console.log(err);

                        // Try to output any data returned
                        // No need to wait for this (no return / await)
                        cloned.text().then((responseText) => {
                            console.log(responseText);
                        }).catch((e) => {
                            console.log('Failed getting response text.', e);
                        });

                        toast.error('There was an unhandled error.');
                        return null;
                    });
                } else if (response.redirected) {
                    // Redirect them to the account/login ?
                    // window.location.href = '/Account/Login';
                    // console.log(response.url);
                    console.log(`Request to ${url} was redirected to ${response.url}.`);
                    toast.error('Authentication expired. Please login again.');

                    let redirectURL = new URL(response.url);

                    let params = new URLSearchParams(redirectURL.search);
                    params.set('redirected', 'true');

                    redirectURL.search = params.toString();

                    window.location.href = redirectURL.toString();

                    return null;
                } else {
                    //Try to get any info we can
                    response.text().then((responseText) => {
                        console.log(responseText);
                    }).catch((e) => {
                        console.log('Failed getting response text.', e);
                    });

                    console.log(response);

                    toast.error('Unhandled error during fetch.');
                    return null;
                }
            }).catch((err) => {
                console.log(err);
                toast.error('Unhandled error during fetch.');
                return null;
            });
        } else {
            toast.error('Attempted fetch without URL.');
            return Promise.resolve(null);
        }
    }
}