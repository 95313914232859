import React from 'react';
import { FormGroupProps } from 'react-bootstrap';
import { DropdownInput } from 'sandhills-react-bootstrap-ui';

interface Props {
    id?: string,
    className?: string,
    clearable?: boolean,
    value?: { value: any, label: string } | null,
    placeholder?: string,
    name?: string,
    disabled?: boolean,
    containerProps?: FormGroupProps,
    onChange: (option: { value: any, label: string } | null) => void,
}

const YEAR_OPTIONS = [...Array((new Date().getFullYear() + 1) - 1900)].map((x, index) => ({ label: (index + 1900).toString(), value: index + 1900 })).reverse();

const YearInput = (props: React.PropsWithChildren<Props>) => {
    return (
        <DropdownInput {...props} searchable options={YEAR_OPTIONS}>
            {props.children}
        </DropdownInput>
    );
}

export default YearInput;