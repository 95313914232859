import React, { useEffect, useState } from 'react';
import { InputLabel, InputNote, TextInput } from 'sandhills-react-bootstrap-ui';
import FlowProvider from '../../providers/FlowProvider';
import ButtonSpinner from '../ButtonSpinner';

interface Props {
    value?: number,
    className?: string,
    disabled?: boolean,
    onChange: (value?: number) => void
}

const PercentInput = (props: React.PropsWithChildren<Props>) => {

    const [numericValue, setNumericValue] = useState(props.value); // This is necessary to allow the component to be unconrolled
    const [displayValue, setDisplayValue] = useState(props.value ? props.value + '%' : '');

    const handleChange = (value: string) => {
        const cleaned = value.replace(/\D/g, '');

        if (cleaned === null || cleaned === '' || cleaned === undefined) {
            setDisplayValue('');
            setNumericValue(undefined);
            props.onChange(undefined);
            return;
        }

        // Anything over 100 is invalid
        let newVal = Number(cleaned);
        if (newVal < 0 || newVal > 100)
            return;

        setNumericValue(newVal);
        setDisplayValue(newVal.toString());

        props.onChange(newVal);
    }

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        if (numericValue)
            setDisplayValue(numericValue.toString());
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (numericValue)
            setDisplayValue(numericValue + '%');
    }

    return (
        <TextInput disabled={props.disabled} maxLength={4} className={props.className} value={displayValue} onFocus={handleFocus} onBlur={handleBlur} onChange={handleChange}>
            {props.children}
        </TextInput>
    );
}

export default PercentInput;