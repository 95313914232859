import React, { useContext, useEffect } from 'react';
import { FormGroupProps } from 'react-bootstrap';
import { DropdownInput } from 'sandhills-react-bootstrap-ui';
import CurrencyUtility from '../../utilities/CurrencyUtility';
import { AppContext } from '../../AppContext';

interface Props {
    id?: string,
    className?: string
}

const PatriotActDisplay = (props: Props) => {
    const context = useContext(AppContext);

    return (
        <div className='w-100 px-3 small'>
            <i>
                To help the government fight the funding of terrorism and money laundering activities, federal law
                requires all financial institutions to obtain, verify, and record information that identifies each
                person who opens an account. As a result, you may be asked your name, address, date of birth, and
                other information that will allow the financial institution to identify you. You may also be asked
                to provide a copy of your driver's license or other identifying documents.
            </i>
        </div>
    );
}

export default PatriotActDisplay;