import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { CheckInput, InputLabel, InputNote, TextInput } from 'sandhills-react-bootstrap-ui';
import FlowProvider from '../../providers/FlowProvider';
import ButtonSpinner from '../ButtonSpinner';

interface Props {
    id: string,
    value?: boolean,
    className?: string,
    required?: boolean | null,
    onChange: (value: boolean) => void
}

const DoosanTermsAndAgreementsCheck = (props: Props) => {

    const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);


    const handleScroll = (e: React.UIEvent) => {
        // Check if the user has scrolled to the bottom of the div
        const element = e.target as HTMLDivElement;
        if (element.scrollHeight - element.scrollTop <= element.clientHeight + 50) {
            setHasScrolledToBottom(true);
        }
    }

    return (
        <div>
            <div onScroll={handleScroll} className='border rounded p-2 bg-white' style={{ height: 300, maxHeight: 300, overflowY: 'auto' }}>
                <p>
                    To help the government fight the funding of terrorism and money laundering activities,
                    Federal law requires all financial institutions to obtain, verify, and record information
                    that identifies each person who opens an account (your loan/lease). What this means for you:
                    When you open an account (your loan/lease), we will ask for your name, address,
                    date of birth, business documents, and other information that will allow us to identify you.
                    We may also ask to see your driver's license or other identifying documents.
                </p>
                <p>
                    IMPORTANT INFORMATION: Except as otherwise prohibited by law, you agree and consent that
                    Clark Equipment Company doing business as Doosan Financial Solutions and any of its affiliates
                    or assigns (“DFS”) may share information with potential lenders about the Applicant(s) that
                    DFS has or may obtain for the purposes, among other things, of evaluating credit applications
                    or servicing account(s). ECOA Notice: The Federal Equal Credit Opportunity Act prohibits
                    creditors from discriminating against credit applicants on the bases of race, color, religion,
                    national origin, sex, marital status, age (providing the applicant has the capacity to enter
                    into a binding contract); because all or part of the applicant's income derives from any public
                    assistance program; or, because the applicant has in good faith exercised any right under
                    the Consumer Credit Protection Act. The federal agency that administers compliance with this
                    law concerning this creditor is Bureau of Consumer Financial Protection, 1700 G Street NW, Washington, DC 20008.
                </p>
                <p>
                    “You,” the "Applicant" (both terms include the business entity as well as all of the individuals named above),
                    certify to us that you are applying for credit for business reasons, and not for personal,
                    family or household purposes. Applicant authorizes DFS and potential lenders to obtain
                    information from others concerning Applicant’s credit and trade standing, including Applicant’s
                    personal credit report (if Applicant is sole proprietorship or if named individuals are providing guaranty),
                    and other relevant information impacting this application, and if the Lease or Loan is approved,
                    from time to time during the term of the Lease or Loan. You authorize DFS and potential lenders
                    to prepare and file against Applicant, a financing statement, in form and substance sufficient
                    to perfect a security interest in collateral together with the proceeds thereof arising from an approved
                    Lease or Loan. In addition to the information requested on this application, DFS and potential lenders may
                    subsequently request additional information from Applicant. As an authorized agent of the applicant company,
                    you represent that you have reviewed this document and the information herein is true, correct and complete.
                    A photo static copy of this authorization shall be as valid as the original. If DFS or potential lenders
                    decline this application, you authorize potential lenders to advise DFS and your dealer of the decline
                    and the reasons for the decline and to provide a copy of this application to DFS. You authorize DFS to
                    provide a copy of this application to other lenders identified by DFS in their sole discretion. You agree
                    that any lender that receives a copy of this application from DFS on your behalf is authorized to obtain
                    information about you as described in this application. Ohio Residents Only: The Ohio laws against discrimination
                    require that all creditors make credit equally available to all creditworthy customers, and that credit reporting
                    agencies maintain separate credit histories on each individual upon request. The Ohio Civil Rights commission
                    administers compliance with this law. New York Residents Only: A consumer report may be requested in conjunction
                    with this application. Upon your request, you will be informed whether or not a consumer report was requested
                    and if such report was requested, informed of the name and address of the consumer reporting agency that furnished
                    the report. Subsequent consumer reports may be requested or utilized in connection with an update, renewal or
                    extension of the credit for which this application is made. Vermont Residents Only: You authorize DFS and lenders
                    to obtain credit reports about you now and in the future for all legitimate purposes associated with this application
                    or the account including, but not limited to: (a) evaluating this application; and (b) renewing, reviewing, modifying,
                    and taking collection action on the account.
                </p>
                <p>
                    'TCPA Consent: We may use automated telephone dialing, text messaging
                    systems and email to provide messages to you about payment due dates, missed payments and other important information.
                    The telephone messages are played by a machine automatically when the telephone is answered,
                    whether answered by you or someone else. These messages may also be recorded by your answering machine.
                    You give us your permission to call or send a text message to any telephone number you have given
                    us and to play pre-recorded messages or send text messages with information about your
                    transaction over the phone. You also give us permission to communicate such information to you via email.
                    You agree that we will not be liable to you for any such calls or electronic communications,
                    even if information is communicated to an unintended recipient. You understand that,
                    when you receive such calls or electronic communications, you may incur a charge from
                    the company that provides you with telecommunications, wireless or Internet services.
                    You agree that we have no liability for such charges.'
                </p>
                <p>
                    'ECOA Notice: The Federal Equal Credit Opportunity Act prohibits creditors from discriminating against
                    credit applicants on the bases of race, color, religion, national origin, sex, marital status, age
                    (providing the applicant has the capacity to enter into a binding contract); because all or part
                    of the applicant's income derives from any public assistance program; or, because the applicant has
                    in good faith exercised any right under the Consumer Credit Protection Act. The federal agency
                    that administers compliance with this law concerning this creditor is Bureau of Consumer Financial Protection,
                    1700 G Street NW, Washington, DC 20008.'
                </p>
                <p>
                    'OHIO RESIDENTS ONLY: The Ohio laws against discrimination require that all creditors make credit equally
                    available to all creditworthy customers, and that credit reporting agencies maintain separate credit histories
                    on each individual upon request. The Ohio Civil Rights commission administers compliance with this law.'
                </p>
                <p>
                    'NEW YORK RESIDENTS ONLY: A consumer report may be requested in conjunction with this application.
                    Upon your request, you will be informed whether or not a consumer report was requested and if such report
                    was requested, informed of the name and address of the consumer reporting agency that furnished the report.
                    Subsequent consumer reports may be requested or utilized in connection with an update, renewal or extension
                    of the credit for which this application is made.'
                </p>
                <p>
                    'VERMONT RESIDENTS ONLY: You authorize DFS and lenders to obtain credit reports about you now and in the
                    future for all legitimate purposes associated with this application or the account including, but not
                    limited to: (a) evaluating this application; and (b) renewing, reviewing, modifying, and taking collection
                    action on the account.'
                </p>
            </div>
            <CheckInput id={props.id} disabled={!hasScrolledToBottom} className={`mr-2 ${props.className}`} value={props.value} onChange={props.onChange} label={`I have read the above disclaimer and consent to its terms.${props.required ? '*' : ''}`} >
                <InputNote>{!hasScrolledToBottom && 'Please scroll through the whole agreement to continue.'}&nbsp;</InputNote>
            </CheckInput>
        </div>
    );
}

export default DoosanTermsAndAgreementsCheck;