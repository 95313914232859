import React from 'react';

interface Props {
    multiple?: boolean,
    accept?: string[],
    onChange: (files: File[]) => void,
    forwardedRef: React.RefObject<HTMLInputElement>
}

const HiddenFileSelect = (props: Props) => {

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target?.files?.length) {
            let files: File[] = [];
            for(let index = 0; index < e.target.files.length; index++){
                let item = e.target.files.item(index);
                if (item)
                    files.push(item);
            }
            props.onChange(files);
        } else
            props.onChange([]);

        // Clear the input
        // Fixes cases where files are selected, removed, then reselected
        e.target.value = '';
    }

    return (
        <input ref={props.forwardedRef} onChange={handleInputChange} accept={props.accept ? props.accept.join(',') : undefined} type='file' multiple={props.multiple} className='d-none' aria-hidden="true" aria-label="Upload File" />
    );
}

export default HiddenFileSelect;