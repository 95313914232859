import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface Props {

}

const NotFound = (props: Props) => {
    return (
        <div className='w-100 d-flex justify-content-center'>
            <div style={{ width: 800, maxWidth: '96%' }} className='mt-5'>
                <Card className='w-100'>
                    <Card.Header style={{ fontSize: 30 }} className='bg-white text-center text-condensed font-weight-bold text-uppercase text-dark-gray'>
                        We're sorry, that page doesn't exist
                    </Card.Header>
                    <Card.Body className='bg-tint'>
                        Please click the button below to be redirected to our home site.
                    </Card.Body>
                    <Card.Footer className='bg-white text-right'>
                        <Link to='/'>
                            <Button variant='primary'>Go Home</Button>
                        </Link>
                    </Card.Footer>
                </Card>
            </div>
        </div>
    );
}

export default NotFound;