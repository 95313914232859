import React from 'react';
import { Col } from 'react-bootstrap';
import InputSwitch from '../../shared/InputSwitch';
import { StepQuestion } from '../../models/StepQuestion';
import { QuestionGroup } from '../../models/QuestionGroup';

interface Props {
    id: string,
    question: StepQuestion,
    group: QuestionGroup,
    columnWidth: number,
    allFlowQuestions: StepQuestion[],
    inputValueDictionary: { [inputDefinitionID: number]: any },
    questionValueDictionary: { [inputDefinitionID: number]: any },
    errors: { [key: number]: string | true | undefined },
    onQuestionValueChange: (question: StepQuestion, value: any) => void,
    // onConditionalValueChange: (inputDefinitionID: number, value: any) => void
}

const Question = (props: Props) => {

    if (!props.question.show)
        return null;

    return (
            <Col xs='12' md={props.columnWidth}>
                <InputSwitch
                    id={props.id}
                    question={props.question}
                    group={props.group}
                    // required={props.question.isRequired}
                    className={props.errors[props.question.inputDefinitionID] ? 'input-error' : ''}
                    errorMessage={props.errors[props.question.inputDefinitionID] !== true && props.errors[props.question.inputDefinitionID] !== undefined ? props.errors[props.question.inputDefinitionID] as string : undefined}
                    // inputType={props.question.controlType}
                    // inputDefinitionID={props.question.inputDefinitionID}
                    // name={props.question.name}
                    // label={props.question.label}
                    inputValues={props.inputValueDictionary}
                    questionValues={props.questionValueDictionary}
                    allFlowQuestions={props.allFlowQuestions}
                    // inputOptions={props.question.options}
                    onChange={props.onQuestionValueChange}
                />
            </Col>
    );
}

export default Question;