import React from 'react';

const JointGuarantorExplanationDisplay = () => {
    return (
        <p>
            Joint Intent - If a Guaranty is being provided for this application or there is more than one Applicant,
            the following must be initialed by both the applicant(s) and all Guarantors. By initialing, we confirm
            that we intend to apply for joint credit or to jointly and severally guarantee credit.
        </p>
    );
}

export default JointGuarantorExplanationDisplay;