import React from 'react';
import { Spinner } from 'react-bootstrap';

interface Props {
    className?: string
}

const ButtonSpinner = (props: Props) => {
    return (
        <Spinner className={`mx-1 ${props.className ?? ''}`} style={{ fontSize: 14, width: '.75rem', height: '.75rem' }} as='span' animation='border' size='sm' />
    )
}

export default ButtonSpinner;