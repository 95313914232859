
import { StepQuestion } from "../models/StepQuestion";
import ValidationResult from "../models/client/ValidationResult";
import ValidationProvider from "../providers/ValidationProvider";

const wrapperCodeTop = `
// params will be an array
// the first value is always the current value of the input being validated
// the remaining values are any possible dependent values in the order they appear as dependencies
// the return of the method should be an error message if invalid, undefined if valid

    try {
`;

const wrapperCodeBottom = `
    } catch(e) {
        console.error(e);
        return 'Validation failed.';
    }

`;

export default class ValidationUtility {
    static executeValidation = async (question: StepQuestion, questionValueDictionary: { [inputDefinitionID: number]: any }): Promise<ValidationResult> => {
        let result: ValidationResult = {
            inputDefinitionID: question.inputDefinitionID,
            isValid: true
        }

        if (!question.validation) {
            return result;
        }

        try {
            const values: any[] = [];
            values.push(questionValueDictionary[question.inputDefinitionID]);
            if (question.dependantQuestions && question.dependantQuestions.length > 0) {
                question.dependantQuestions.forEach(dependantQuestion => {
                    values.push(questionValueDictionary[dependantQuestion.inputDefinitionID]);
                });
            }

            const code = `${wrapperCodeTop}${question.validation}${wrapperCodeBottom}`;
            const validationFunction = Function("ValidationProvider", 'params', code);
            const validationResult = await validationFunction(ValidationProvider, values);

            if (validationResult === undefined || validationResult === null || validationResult === '') {
                return result;
            } else {
                result.isValid = false;
                result.message = validationResult;
                return result;
            }
        } catch (e) {
            console.error(e);
            result.isValid = false;
            result.message = 'Validation failed.';
            return result;
        }
    }
}