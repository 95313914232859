import React from 'react';
import { DropdownInput } from 'sandhills-react-bootstrap-ui';
import { SelectOption } from '../../models/SelectOption';

interface Props {
    value?: SelectOption | null,
    className?: string,
    disabled?: boolean,
    placeholder?: string,
    onChange: (value: { label: string, value: any } | null) => void
}

const options = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map((month, index) => ({ label: month, value: index + 1 }));

const MonthDropdown = (props: React.PropsWithChildren<Props>) => {


    return (
        <DropdownInput disabled={props.disabled} className={props.className} placeholder={props.placeholder} searchable value={props.value} onChange={props.onChange} options={options}>
            {props.children}
        </DropdownInput>
    )
}

export default MonthDropdown;