import React, { useEffect, useRef, useState } from 'react';
import { CheckInput, InputLabel, InputNote, TextInput } from 'sandhills-react-bootstrap-ui';
import FlowProvider from '../../providers/FlowProvider';
import ButtonSpinner from '../ButtonSpinner';

interface Props {
    id: string,
    value?: boolean,
    className?: string,
    required?: boolean | null,
    onChange: (value: boolean) => void
}

const TermsAndAgreementsCheck = (props: Props) => {
    const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);

    const handleScroll = (e: React.UIEvent) => {
        // Check if the user has scrolled to the bottom of the div
        const element = e.target as HTMLDivElement;
        if (element.scrollHeight - element.scrollTop <= element.clientHeight + 50) {
            setHasScrolledToBottom(true);
        }
    }

    return (
        <div>
            <div onScroll={handleScroll} className='border rounded p-2 bg-white' style={{ height: 300, maxHeight: 300, overflowY: 'auto' }}>
                By checking the box below, and clicking "Next", I/We
                <ul className='checkmarks'>
                    <li>
                        Affirm that I/We have read, understand and agree to this website’s  &nbsp;
                        <a style={{ textDecoration: 'underline' }} href="https://www.gocurrency.com/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>, &nbsp;
                        <a style={{ textDecoration: 'underline' }} href="https://www.gocurrency.com/legal" target="_blank" rel="noreferrer">Legal Disclaimer</a>,
                        and <a style={{ textDecoration: 'underline' }} href="https://www.gocurrency.com/terms-of-use" target="_blank" rel="noreferrer">Terms of Use</a>.
                    </li>
                    <li>
                        Affirmatively consent and agree: (i) that I/We hereby represent that I/we am/are authorized to provide all information submitted and
                        hereby provide my/our written Instructions to Express Tech-Financing, LLC and its affiliates, assigns, successors and lending partners
                        (collectively "Currency") for it to obtain information concerning the applicant and me/us from any company, entity, organization, government
                        agency (including law enforcement) or business, including my/our consumer and business credit report(s), employment information, business
                        dealings and trade references; (ii) that Currency may use and share any gathered information from or about me/us with lenders for consideration
                        for a consumer or commercial loan, including any loan or lease to finance the purchase of equipment; and (iii) that should any lenders
                        provide me/us with credit, pursuant to this application, this affirmation shall remain in full force and effect and these lenders or lending
                        sources, shall have the right to periodically update my/our credit information from any source, including requesting or obtaining additional
                        business credit report(s) through any credit reporting agencies, companies, or bureaus, for the purposes of reviewing the account(s),
                        modifying the credit provided, invoicing, taking collection actions, and/or for any other legitimate purpose associated with the credit
                        account, as needed.
                    </li>
                    <li>
                        Represent all information provided is true, accurate and complete.
                    </li>
                    <li>
                        Understand that these written instructions to Currency may result in a soft or hard credit inquiry related to Currency or any
                        of its lending partners appearing on my/our credit report(s).
                    </li>
                    <li>
                        Understand and consent to subsequent credit inquiries if my/our application for a credit product is matched with one of
                        Currency's lending partners, each of which may appear on my/our credit report(s).
                    </li>
                    <li>
                        Further acknowledge, consent, and agree that Currency may obtain information from my/our consumer report(s) and
                        otherwise in order to: (i) confirm my/our identity; (ii) avoid fraudulent transactions in my/our name; (iii) to
                        evaluate my/our and any applicant’s initial and continuing creditworthiness; and (iv) to update, renew, confirm,
                        extend, supplement, enforce and collect on any credit transaction resulting from my/our application.
                    </li>
                    <li>
                        Waive any right or claim I/We would otherwise have under the Fair Credit Reporting Act, the Drivers' Policy Protection Act
                        and the Gramm-Leach-Bliley Act in the absence of this continuing consent
                    </li>
                    <li>
                        I/We consent Currency to send any notices required by law electronically to the electronic mail address(es) I/We have provided.
                    </li>
                    <li>
                        Confirm that I/we can receive and access electronic records, including the ability to receive email and access PDF files.
                    </li>
                    <li>
                        Any Agreement may be executed by an electronic signature. Such electronic signature shall have the same force and effect
                        as any original ink-signed document. Facsimile or copies of this fully executed document shall be considered to be an
                        original document for all purposes and admissible in any Court or administrative tribunal. I/We also understand the option
                        to opt out of electronic document delivery and signature and receive any document in non-electronic form as explained in
                        section 7 of the <a style={{ textDecoration: 'underline' }} href="https://www.gocurrency.com/terms-of-use" target="_blank" rel="noreferrer">Terms of Use</a>.
                    </li>
                </ul>
            </div>
            <CheckInput id={props.id} disabled={!hasScrolledToBottom} className={`mr-2 ${props.className}`} value={props.value} onChange={props.onChange} label={`I have read the above disclaimer and consent to its terms.${props.required ? '*' : ''}`}>
                <InputNote>{!hasScrolledToBottom && 'Please scroll through the whole agreement to continue.'}&nbsp;</InputNote>
            </CheckInput>
        </div>
    );
}

export default TermsAndAgreementsCheck;