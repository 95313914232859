import React, { useEffect } from 'react';
import { DropdownInput } from 'sandhills-react-bootstrap-ui';
import { SelectOption } from '../../models/SelectOption';
import FlowProvider from '../../providers/FlowProvider';

interface Props {
    value?: SelectOption,
    className?: string,
    onChange: (value: { label: string, value: any } | null) => void
}

// const options = [
//     'Coorporation',
//     'Limited Liability Company',
//     'Sole Propietorship',
//     'Non-Profit Corporation',
//     'General Partnership',
//     'Limited Partnership',
//     'Municipal',
//     'Other'
// ].map(month => ({ label: month, value: month }));

const BusinessStructureDropdown = (props: React.PropsWithChildren<Props>) => {

    const [options, setOptions] = React.useState<SelectOption[]>([]);

    useEffect(() => {
        FlowProvider.GetLegalEntityTypes().then((result) => {
            if (result) {
                const options: SelectOption[] = result.options.map((op => {
                    return {
                        label: op.label,
                        value: op.label,
                        isDisabled: false,
                    }
                }));
                setOptions(options);
            }
        });
    }, []);

    return (
        <DropdownInput searchable className={props.className} value={props.value} onChange={props.onChange} options={options}>
            {props.children}
        </DropdownInput>
    )
}

export default BusinessStructureDropdown;