import React from 'react';

interface Props {
    value?: { url: string, value: number };
}

const ImageDisplay = (props: Props) => {

    if (!props.value)
        return null;

    return (
        <div className='d-flex align-items-center justify-content-center mb-3'>
            <img className='rounded' src={props.value.url} alt='' style={{ maxHeight: '200px' }} />
        </div>
    );
}

export default ImageDisplay;