// Component to render the EIN input field
import React, { useEffect, useState } from 'react';
import { InputNote, TextInput } from 'sandhills-react-bootstrap-ui';

interface Props {
  value?: string,
  className?: string,
  onChange: (value: string) => void
}

const EINInput = (props: React.PropsWithChildren<Props>) => {

  const handleChange = (value: string) => {
    const cleaned = value.replace(/\D/g, '');

    // Format as EIN as you type (XX-XXXXXXX)
    const newVal = cleaned.length > 2 && cleaned.length < 9
      ? `${cleaned.slice(0, 2)}-${cleaned.slice(2)}`
      : cleaned.length >= 9
        ? `${cleaned.slice(0, 2)}-${cleaned.slice(2, 9)}`
        : cleaned;

    props.onChange(newVal);
  }

  return (
    <TextInput maxLength={10} value={props.value} className={props.className} onChange={handleChange}>
      {props.children}
    </TextInput>
  );
}

export default EINInput;