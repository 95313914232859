import React from 'react';
import { DropdownInput, RadioInput } from 'sandhills-react-bootstrap-ui';
import { SelectOption } from '../../models/SelectOption';

interface Props {
    name: string,
    className?: string,
    value?: string | number | boolean | null | undefined,
    onChange: (value: string | number | boolean | null | undefined) => void
}

const PhoneNumberTypeRadio = (props: React.PropsWithChildren<Props>) => {
    return (
        <RadioInput name={props.name} className={props.className} value={props.value} onChange={props.onChange}>
            {props.children}
            <RadioInput.Radio id='phone-type-cell' className='no-select' label='Cell Phone' value='cell' />
            <RadioInput.Radio id='phone-type-home' className='no-select' label='Home Phone' value='home' />
        </RadioInput>
    )
}

export default PhoneNumberTypeRadio;