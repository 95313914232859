import React, { useEffect } from 'react';
import { DropdownInput } from 'sandhills-react-bootstrap-ui';
import { SelectOption } from '../../models/SelectOption';
import FlowProvider from '../../providers/FlowProvider';

interface Props {
    value?: SelectOption,
    className?: string,
    onChange: (value: { label: string, value: any } | null) => void
}

// const options = [
//     'Attorney',
//     'Certified Public Accountant',
//     'Clergy',
//     'Dentist',
//     'Educational Professional',
//     'Entertainer',
//     'Government Worker',
//     'Healthcare Medical',
//     'Homemaker',
//     'Laborer',
//     'Medical Doctor',
//     'Military',
//     'Office Worker',
//     'Other / Miscellaneous',
//     'Owner / Self Employed',
//     'Professional',
//     'Professional Athlete',
//     'Real Estate Agent Broker',
//     'Retired',
//     'Sales Person',
//     'Senior Manager Executive',
//     'Service Worker',
//     'Student',
//     'Supervisor Manager Admin',
//     'Unemployed'
// ].map(month => ({ label: month, value: month }));

const OccupationDropdown = (props: React.PropsWithChildren<Props>) => {
    const [options, setOptions] = React.useState<SelectOption[]>([]);

    useEffect(() => {
        FlowProvider.GetOccupationTypes().then((result) => {
            if (result)
                setOptions(result.options);
        });
    }, []);

    return (
        <DropdownInput searchable value={props.value} className={props.className} onChange={props.onChange} options={options}>
            {props.children}
        </DropdownInput>
    )
}

export default OccupationDropdown;