import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import Footer from './Footer';
import Header from './Header';
import $ from 'jquery';

interface Props {
    loading: boolean,
    fatalError: boolean
}

const Layout = (props: React.PropsWithChildren<Props>) => {

    useEffect(() => {
        if (!props.loading) {
            $('#layout-root').css('display', 'flex').hide().fadeIn();
        }
    }, [props.loading]);

    if (props.loading)
        return (
            <div id='loading-spinner' className='d-flex h-100 align-items-center justify-content-center'>
                <Spinner as='span' animation='border' />
            </div>
        );

    if (props.fatalError) {
        return (
            <div className='d-flex h-100 align-items-center justify-content-center'>
                <span>There was a fatal error loading the application. Please refresh the page and try again.</span>
            </div>
        );
    }

    return (
        <div id='layout-root' style={{ display: 'none' }} className='flex-column h-100'>
            <Header />
            <div className='d-flex flex-grow-1'>
                {props.children}
            </div>
            <Footer />
        </div>
    );
}

export default Layout;