
import React, { useContext, useEffect } from "react";
import { AppContext } from "../AppContext";

interface Props {

}

const Header = (props: Props) => {
    const context = useContext(AppContext);

    const [smallScreen, setSmallScreen] = React.useState<boolean>(window.innerWidth < 768);

    useEffect(() => {
        window.addEventListener('resize', () => {
            setSmallScreen(window.innerWidth < 768);
        });
    }, []);

    return (
        <div style={{ height: smallScreen ? 50 : 70 }} className='no-print w-100 p-1 d-flex justify-content-around bg-white shadow align-items-center'>
            <a style={{ height: '70%' }} target="_blank" href="http://www.gocurrency.com/">
            <img style={{ height: '90%' }} src={context.logoURL ?? 'images/currency_finance.png'} />
            </a>
            <div className='text-center'>
                <span className='text-light-gray'>Questions?</span><br />
                <a href={`tel:${context.phoneNumber}`} style={{ fontSize: smallScreen ? 16 : 20 }} className='text-secondary font-weight-bold hover'>{context.phoneNumber}</a>
            </div>
        </div>
    );
}

export default Header;