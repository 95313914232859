// THIS FILE WAS GENERATED AUTOMATICALLY
//
// DO NOT EDIT THIS FILE DIRECTLY

import { Validation } from '../models/Validation';


import FetchProvider from './client/FetchProvider';

export default class ValidationProvider {
    // -------------------------------------------------------------------------
    // ------------------------------ GET METHODS ------------------------------
    // -------------------------------------------------------------------------

    static IsEmailValid(email: string | null | undefined): Promise<Validation | null | undefined> {
        return FetchProvider.fetch<Validation>(`/api/validation/isemailvalid?email=${encodeURIComponent(email || '')}`);
    }

    static IsPhoneNumberValid(phoneNumber: string | null | undefined, countryID?: number | null | undefined): Promise<Validation | null | undefined> {
        return FetchProvider.fetch<Validation>(`/api/validation/isphonenumbervalid?phoneNumber=${encodeURIComponent(phoneNumber || '')}&countryID=${encodeURIComponent(countryID || '')}`);
    }

    static IsEquipmentModelValid(model: string | null | undefined, categoryID?: number | null | undefined): Promise<Validation | null | undefined> {
        return FetchProvider.fetch<Validation>(`/api/validation/isequipmentmodelvalid?model=${encodeURIComponent(model || '')}&categoryID=${encodeURIComponent(categoryID || '')}`);
    }

    static IsPostalCodeValid(postalCode: string | null | undefined, city: string | null | undefined, stateID?: number | null | undefined, countryID?: number | null | undefined): Promise<Validation | null | undefined> {
        return FetchProvider.fetch<Validation>(`/api/validation/ispostalcodevalid?postalCode=${encodeURIComponent(postalCode || '')}&city=${encodeURIComponent(city || '')}&stateID=${encodeURIComponent(stateID || '')}&countryID=${encodeURIComponent(countryID || '')}`);
    }

    // --------------------------------------------------------------------------
    // ------------------------------ POST METHODS ------------------------------
    // --------------------------------------------------------------------------
}