// THIS FILE WAS GENERATED AUTOMATICALLY
//
// DO NOT EDIT THIS FILE DIRECTLY

import { VendorStepFlow } from '../models/VendorStepFlow';
import { SelectOptionList } from '../models/SelectOptionList';
import { DerivedInputValues } from '../models/DerivedInputValues';
import { PostalCodeInfo } from '../models/PostalCodeInfo';
import { State } from '../models/State';
import { Country } from '../models/Country';
import { PhoneNumberValidation } from '../models/PhoneNumberValidation';
import { SaveApplication } from '../models/SaveApplication';
import { SaveApplicationResult } from '../models/SaveApplicationResult';


import FetchProvider from './client/FetchProvider';

export default class FlowProvider {
    // -------------------------------------------------------------------------
    // ------------------------------ GET METHODS ------------------------------
    // -------------------------------------------------------------------------

    static GetFormForVendor(uniqueSessionID: string | null | undefined, vendorID: number | null | undefined, TLPID: number | null | undefined, listingID?: number | null | undefined, encryptedCRMID?: string | null | undefined, dealType?: string | null | undefined, aipid?: number | null | undefined): Promise<VendorStepFlow | null | undefined> {
        return FetchProvider.fetch<VendorStepFlow>(`/api/flow/getformforvendor?uniqueSessionID=${encodeURIComponent(uniqueSessionID || '')}&vendorID=${encodeURIComponent(vendorID || '')}&TLPID=${encodeURIComponent(TLPID || '')}&listingID=${encodeURIComponent(listingID || '')}&encryptedCRMID=${encodeURIComponent(encryptedCRMID || '')}&dealType=${encodeURIComponent(dealType || '')}&aipid=${encodeURIComponent(aipid || '')}`);
    }

    static SearchCategoryOptions(vendorID: number | null | undefined, TLPID: number | null | undefined, manufacturer: string | null | undefined, model: string | null | undefined, search: string | null | undefined): Promise<SelectOptionList | null | undefined> {
        return FetchProvider.fetch<SelectOptionList>(`/api/flow/searchcategoryoptions?vendorID=${encodeURIComponent(vendorID || '')}&TLPID=${encodeURIComponent(TLPID || '')}&manufacturer=${encodeURIComponent(manufacturer || '')}&model=${encodeURIComponent(model || '')}&search=${encodeURIComponent(search || '')}`);
    }

    static SearchManufacturers(vendorID: number | null | undefined, TLPID: number | null | undefined, search: string | null | undefined): Promise<SelectOptionList | null | undefined> {
        return FetchProvider.fetch<SelectOptionList>(`/api/flow/searchmanufacturers?vendorID=${encodeURIComponent(vendorID || '')}&TLPID=${encodeURIComponent(TLPID || '')}&search=${encodeURIComponent(search || '')}`);
    }

    static SearchModels(vendorID: number | null | undefined, TLPID: number | null | undefined, search: string | null | undefined, manufacturer?: string | null | undefined, categoryID?: number | null | undefined): Promise<SelectOptionList | null | undefined> {
        return FetchProvider.fetch<SelectOptionList>(`/api/flow/searchmodels?vendorID=${encodeURIComponent(vendorID || '')}&TLPID=${encodeURIComponent(TLPID || '')}&search=${encodeURIComponent(search || '')}&manufacturer=${encodeURIComponent(manufacturer || '')}&categoryID=${encodeURIComponent(categoryID || '')}`);
    }

    static GetLegalEntityTypes(): Promise<SelectOptionList | null | undefined> {
        return FetchProvider.fetch<SelectOptionList>(`/api/flow/getlegalentitytypes`);
    }

    static GetCitizenshipTypes(): Promise<SelectOptionList | null | undefined> {
        return FetchProvider.fetch<SelectOptionList>(`/api/flow/getcitizenshiptypes`);
    }

    static GetEmploymentTypes(): Promise<SelectOptionList | null | undefined> {
        return FetchProvider.fetch<SelectOptionList>(`/api/flow/getemploymenttypes`);
    }

    static GetMaritalTypes(): Promise<SelectOptionList | null | undefined> {
        return FetchProvider.fetch<SelectOptionList>(`/api/flow/getmaritaltypes`);
    }

    static GetCompanyTypes(): Promise<SelectOptionList | null | undefined> {
        return FetchProvider.fetch<SelectOptionList>(`/api/flow/getcompanytypes`);
    }

    static GetOccupationTypes(): Promise<SelectOptionList | null | undefined> {
        return FetchProvider.fetch<SelectOptionList>(`/api/flow/getoccupationtypes`);
    }

    static GetListingLinkInformation(listingLink?: string | null | undefined): Promise<DerivedInputValues | null | undefined> {
        return FetchProvider.fetch<DerivedInputValues>(`/api/flow/getlistinglinkinformation?listingLink=${encodeURIComponent(listingLink || '')}`);
    }

    static GetPostalCodeInformation(postalCode: string | null | undefined): Promise<PostalCodeInfo | null | undefined> {
        return FetchProvider.fetch<PostalCodeInfo>(`/api/flow/getpostalcodeinformation?postalCode=${encodeURIComponent(postalCode || '')}`);
    }

    static GetStates(countryID?: number | null | undefined): Promise<SelectOptionList | null | undefined> {
        return FetchProvider.fetch<SelectOptionList>(`/api/flow/getstates?countryID=${encodeURIComponent(countryID || '')}`);
    }

    static GetStateByCode(stateCode: string | null | undefined): Promise<State | null | undefined> {
        return FetchProvider.fetch<State>(`/api/flow/getstatebycode?stateCode=${encodeURIComponent(stateCode || '')}`);
    }

    static GetCountries(): Promise<SelectOptionList | null | undefined> {
        return FetchProvider.fetch<SelectOptionList>(`/api/flow/getcountries`);
    }

    static GetCountry(countryID: number | null | undefined): Promise<Country | null | undefined> {
        return FetchProvider.fetch<Country>(`/api/flow/getcountry?countryID=${encodeURIComponent(countryID || '')}`);
    }

    static GetCountryByCode(countryCode: string | null | undefined): Promise<Country | null | undefined> {
        return FetchProvider.fetch<Country>(`/api/flow/getcountrybycode?countryCode=${encodeURIComponent(countryCode || '')}`);
    }

    static GetIndustries(): Promise<SelectOptionList | null | undefined> {
        return FetchProvider.fetch<SelectOptionList>(`/api/flow/getindustries`);
    }

    static FormatValidatePhoneNumber(phoneNumber: string | null | undefined, countryID?: number | null | undefined): Promise<PhoneNumberValidation | null | undefined> {
        return FetchProvider.fetch<PhoneNumberValidation>(`/api/flow/formatvalidatephonenumber?phoneNumber=${encodeURIComponent(phoneNumber || '')}&countryID=${encodeURIComponent(countryID || '')}`);
    }

    static GetDealTypes(): Promise<SelectOptionList | null | undefined> {
        return FetchProvider.fetch<SelectOptionList>(`/api/flow/getdealtypes`);
    }

    // --------------------------------------------------------------------------
    // ------------------------------ POST METHODS ------------------------------
    // --------------------------------------------------------------------------

    static SaveApplicationProgressGetNextStep(input: SaveApplication): Promise<SaveApplicationResult | null | undefined> {
        return FetchProvider.fetch<SaveApplicationResult>(`/api/flow/saveapplicationprogressgetnextstep`, input);
    }

    static SubmitApplication(input: SaveApplication): Promise<SaveApplicationResult | null | undefined> {
        return FetchProvider.fetch<SaveApplicationResult>(`/api/flow/submitapplication`, input);
    }
}