// THIS FILE WAS GENERATED AUTOMATICALLY
//
// DO NOT EDIT THIS FILE DIRECTLY

import { AdditionalGuarantorForm } from '../models/AdditionalGuarantorForm';
import { SaveGuarantor } from '../models/SaveGuarantor';
import { Base } from '../models/Base';
import { StipulationList } from '../models/StipulationList';
import { StipulationFileUpload } from '../models/StipulationFileUpload';


import FetchProvider from './client/FetchProvider';

export default class ApplicationProvider {
    // -------------------------------------------------------------------------
    // ------------------------------ GET METHODS ------------------------------
    // -------------------------------------------------------------------------

    static GetAdditionalGuarantorForm(applicationGUID: string | null | undefined): Promise<AdditionalGuarantorForm | null | undefined> {
        return FetchProvider.fetch<AdditionalGuarantorForm>(`/api/application/getadditionalguarantorform?applicationGUID=${encodeURIComponent(applicationGUID || '')}`);
    }

    static GetStipulationsForApplication(applicationGUID: string | null | undefined, isForVendor?: boolean | null | undefined): Promise<StipulationList | null | undefined> {
        return FetchProvider.fetch<StipulationList>(`/api/application/getstipulationsforapplication?applicationGUID=${encodeURIComponent(applicationGUID || '')}&isForVendor=${encodeURIComponent(isForVendor || '')}`);
    }

    // --------------------------------------------------------------------------
    // ------------------------------ POST METHODS ------------------------------
    // --------------------------------------------------------------------------

    static SaveGuarantor(input: SaveGuarantor): Promise<Base | null | undefined> {
        return FetchProvider.fetch<Base>(`/api/application/saveguarantor`, input);
    }

    static UploadFileForStipulation(input: StipulationFileUpload): Promise<Base | null | undefined> {
        const formData = new FormData();
        for (let key in input) {
            formData.append(key, input[key]);
        }
        
        return FetchProvider.fetch<Base>(`/api/application/uploadfileforstipulation`, formData);
    }
}