import React from 'react';
import { Spinner } from 'react-bootstrap';

const PageLoading = () => {
    return (
        <div className='d-flex align-items-center justify-content-center h-100 w-100'>
            <Spinner animation='border' />
        </div>
    )
}

export default PageLoading;