import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { QuestionGroup } from '../../models/QuestionGroup';
import { SaveGuarantor } from '../../models/SaveGuarantor';
import { StepQuestion } from '../../models/StepQuestion';
import { VendorStep } from '../../models/VendorStep';
import ValidationResult from '../../models/client/ValidationResult';
import ApplicationProvider from '../../providers/ApplicationProvider';
import AnimatedCheck from '../../shared/AnimatedCheck';
import PageLoading from '../../shared/PageLoading';
import ValidationUtility from '../../utilities/ValidationUtility';
import StatusStep from '../home/StatusStep';
import Step from '../home/Step';

const AdditionalGuarantorDetail = () => {
    const { applicationGUID } = useParams();
    const applicationID = useRef<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);

    const [questionGroups, setQuestionGroups] = useState<QuestionGroup[]>([]);
    const [inputValueDictionary, setInputValueDictionary] = useState<{ [inputDefinitionID: number]: any }>({}); // This is the value of the input control
    const [questionValueDictionary, setQuestionValueDictionary] = useState<{ [inputDefinitionID: number]: any }>({}); // This is the value to be saved on the application

    const allQuestions = useRef<StepQuestion[]>([]);
    const [errorQuestions, setErrorQuestions] = useState<{ [key: number]: string | true | undefined }>({});

    useEffect(() => {
        setLoading(true);
        ApplicationProvider.GetAdditionalGuarantorForm(applicationGUID).then((result) => {
            if (result?.success) {
                applicationID.current = result.applicationID;
                setQuestionGroups(result.questionGroups);
                allQuestions.current = result.questionGroups.flatMap(x => x.questions);
            }
        }).finally(() => setLoading(false));
    }, [applicationGUID]);

    const validateForm = async (): Promise<boolean> => {
        let hasError = false;

        let errors: { [key: number]: string | true | undefined } = {};

        // Do all required validations
        questionGroups.forEach(group => {
            group.questions.forEach(question => {
                if (question.isRequired && (questionValueDictionary[question.inputDefinitionID] === null || // If required and no value
                    questionValueDictionary[question.inputDefinitionID] === undefined ||
                    questionValueDictionary[question.inputDefinitionID].toString().trim() === '' ||
                    questionValueDictionary[question.inputDefinitionID] === false)
                ) {
                    errors[question.inputDefinitionID] = true;
                    hasError = true;
                } else {
                    errors[question.inputDefinitionID] = undefined;
                }
            });
        });

        // Do all custom validations
        let validationProms: Promise<ValidationResult>[] = [];
        questionGroups.forEach(group => {
            group.questions.forEach(question => {
                if (question.show && question.validation && errors[question.inputDefinitionID] === undefined) {
                    validationProms.push(ValidationUtility.executeValidation(question, questionValueDictionary));
                }
            });
        });

        let validationResults = await Promise.all(validationProms);
        validationResults.forEach(result => {
            if (result.isValid === false) {
                errors[result.inputDefinitionID] = result.message;
                hasError = true;
            } else {
                errors[result.inputDefinitionID] = undefined;
            }
        });

        setErrorQuestions(errors);

        return !hasError;
    }

    const handleSave = () => {
        setSaving(true);

        validateForm().then((valid) => {
            if (valid) {
                let input: SaveGuarantor = {
                    applicationID: applicationID.current,
                    questionValues: questionValueDictionary
                }

                return ApplicationProvider.SaveGuarantor(input).then((result) => {
                    if (result?.success) {
                        setSuccess(true);
                    }
                });
            } else {
                toast.error('Please correct the errors highlighted in red.', { autoClose: 5000 });
            }
        }).finally(() => 
            setSaving(false)
        );
    }

    const handleQuestionValueChange = (question: StepQuestion, inputValue: any) => {
        const questionValue = inputValue?.value ?? inputValue?.industryID ?? inputValue ?? null;

        setQuestionValueDictionary(current => ({ ...current, [question.inputDefinitionID]: questionValue }));
        setInputValueDictionary(current => ({ ...current, [question.inputDefinitionID]: inputValue }));
    }

    if (loading)
        return <PageLoading />;

    return (
        <Container fluid='md' className='py-3'>
            {success ?
                <StatusStep
                    message={
                        <div className='text-center w-100'>
                            <h3 className='text-uppercase text-condensed'>Thank you for applying with Currency<img alt='' style={{ marginBottom: -5 }} src='images/coin.png' width={10} /></h3>

                            <>
                                <span>You should hear from a Currency representative shortly.</span>
                                <br />
                                <AnimatedCheck className='text-secondary' />
                            </>

                            <h4>Reference #: <b>{applicationID.current}</b></h4>
                        </div>
                    }
                />
                :
                <Step
                    allFlowQuestions={allQuestions.current}
                    disablePrevious
                    errors={errorQuestions}
                    inputValueDictionary={inputValueDictionary}
                    loading={saving}
                    onQuestionValueChange={handleQuestionValueChange}
                    questionValueDictionary={questionValueDictionary}
                    step={{ stepName: 'Additional Guarantor', label: 'Additional Guarantor Information', questionGroups } as VendorStep}
                    nextButtonLabel='Submit'
                    onNext={handleSave}
                    visible
                    isLastStep
                />
            }

        </Container>
    );

}

export default AdditionalGuarantorDetail;

