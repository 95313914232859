export default class CurrencyUtility {
    static formatCurrency = (value: number, cultureCode = 'en-US', currencyCode = 'USD', compact: boolean = false) => {
        if (value === undefined || value === null || currencyCode === undefined || currencyCode === null) {
            return '';
        }

        let formatter = new Intl.NumberFormat(cultureCode, {
            style: 'currency',
            currencyDisplay: 'narrowSymbol',
            currency: currencyCode,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            // @ts-ignore
            notation: compact ? 'compact' : 'standard'
        });

        return formatter.format(value);
    }
}