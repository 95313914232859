import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { AppContext } from './AppContext';
import ColorUtility from './utilities/ColorUtility';

interface Props {

}

const ThemeProvider = (props: Props) => {
  const context = useContext(AppContext);

  let keys = Object.keys(context.theme);

  let lines = [];

  lines.push(
    ...keys.map(key => {
      const hsl = ColorUtility.colorToHSL(context.theme[key]);
      return (
        `--${key}: hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%);`
      )
    })
  );
  lines.push(
    ...keys.map(key => (
      `--${key}-h: ${ColorUtility.colorToHSL(context.theme[key])[0]};`
    ))
  );
  lines.push(
    ...keys.map(key => (
      `--${key}-s: ${ColorUtility.colorToHSL(context.theme[key])[1]}%;`
    ))
  );
  lines.push(
    ...keys.map(key => (
      `--${key}-l: ${ColorUtility.colorToHSL(context.theme[key])[2]}%;`
    ))
  );

  
  return (
    <Helmet>
      <style>
        {
          `:root {
            ${lines.join('\n')}
          }`
        }
      </style>
    </Helmet>
  );
}

export default ThemeProvider;