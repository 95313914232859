import React from 'react';
import { DropdownInput, RadioInput } from 'sandhills-react-bootstrap-ui';
import { SelectOption } from '../../models/SelectOption';

interface Props {
    name: string,
    className?: string,
    value?: string | number | boolean | null | undefined,
    onChange: (value: string | number | boolean | null | undefined) => void
}

const OwnOrRentRadio = (props: React.PropsWithChildren<Props>) => {
    return (
        <RadioInput name={props.name} className={props.className} value={props.value} onChange={props.onChange}>
            {props.children}
            <RadioInput.Radio id='own-rent-own' className='no-select' label='Own' value='own' />
            <RadioInput.Radio id='own-rent-rent' className='no-select' label='Rent' value='rent' />
        </RadioInput>
    )
}

export default OwnOrRentRadio;