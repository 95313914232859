import { createContext } from 'react';
import Context from './models/client/Context';
import { Theme } from './models/Theme';

export const AppContext = createContext<Context>({ //This is the default - it should never be hit so the objects don't need to be valid
    vendorID: 0,
    topLevelPartnerVendorID: 0,
    highestTopLevelPartnerVendorID: 0,
    defaultCurrencyCode: 'USD',
    theme: {} as Theme,
    phoneNumber: '',
    supportEmail: '',
    logoURL: '',
    uniqueClientID: ''
});